<template>
  <div class="Vocational">
    <div style="width:100%;background:#ffffff">
    <div class="search">
         <div class="searchContainer">
        <el-input
          class="input"
          placeholder="请输入培训课程名称"
          v-model="params.keyword"
          clearable
        >
        </el-input>
        <img
          @click="searchHandle"
          class="searchIcon"
          src="../../assets/images/搜索.png"
        />  
        <div
          @click="searchHandle" 
          class="input-button"
        >搜索</div>
         </div>
    </div>
    <div class="lineBox"></div>
    <div class="type-box">
      <div class="typeCard">
        <div class="type">
          培训类型 
          <div class="blockBox"></div>
          <span
            :class="['el-btn', params.training_type === 'all' ? 'active' : 'no-active']"
            @click="choseTypeHandle('all')"
            key="allType"
            >不限</span
          >
          <template v-for="(item, key, index) in techfieldList">
            <span
              @click="choseTypeHandle(item)"  
              :class="['el-btn', params.training_type.toString() === item.toString() ? 'active' : 'no-active']"
              :key="index"
              >{{ key }}</span
            >
          </template>
        </div>
        <div class="nature" v-empty="course">
          培训性质 
          <div class="block-Box"></div>
          <span
            :class="['el-btn', params.course_type === 'all' ? 'active' : 'no-active']"
            key="natureBtnAll"
            @click="choseNatureHandle('all')"
            >不限</span
          >
          <template v-for="(item, index) in nature">
            <span
              :class="['el-btn', params.course_type.toString() === item.value.toString() ? 'active' : 'no-active']"
              @click="choseNatureHandle(item.value)"
              :key="index"
              >{{ item.label }}</span
            ></template
          >
        </div>
      </div>
    </div>
    </div>
    <div class="classify">
      <div class="courses" v-loading="fullscreenLoading">
        <div
          class="course"
          v-for="(item, index) in course"
          :key="index"
          @click="toCourse(item.billid)"
        >
          <div class="courseCover">
            <!-- <el-image 
                    class="themImg"
              :fit="cover"  :src="`https://console.qzcjrh.cn${item.course_photo}`" alt="" /> -->
              <el-image class="img" fit="cover" :src="httpConfig.baseURL+item.course_photo" alt="" />
            <div class="courseInfo">
              已有{{ item.childVOs.length }}人报名
            </div>
          </div>
          <div class="teacher">
            <p v-if="item.course_name">{{ item.course_name }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="Pagination">
      <Pagination
        @changeIndex="setCurrentPage"
        :total="total"
        :totalPage="totalPage"
        :pageSize="params.pageSize"
      ></Pagination>
    </div>
  </div>
</template>

<script>

import Pagination from "../../components/Pagination.vue";
import { mapGetters } from "vuex";
import { getTrainingcourses } from "../../api/vocational/vocational.js";
import { objDeepCopy } from '../../common/utils/tool';
import httpConfig from "../../common/utils/config";
import defaultImg from '../../assets/images/公司默认图片2.png'
export default {
  computed: {
    ...mapGetters(["techfieldList"]),
  },
  data() {
    return {
      httpConfig,
      defaultImg,
      input: "",
      nature: [
        { label: "线上培训", value: "1" },
        { label: "线下培训", value: "2" },
        { label: "线上+线下", value: "3" },
      ],
      course: [],
      total: 0,
      fullscreenLoading: false,
      totalPage: 0,
      params: {
        course_type: "all", 
        training_type: "all", 
        keyword: "",
        orderstr: "make_date", //click_num
        pageNo: 1,
        pageSize: 10, // 分页-每页显示10张
      },
    };
  },
  created() {
    this.getData();
  },
  methods: {
    searchHandle() {
      this.getData();
    },
    async getData() {
      let that = this;
      let params = objDeepCopy(that.params)
      params.course_type=params.course_type === 'all'?'':params.course_type
      params.training_type=params.training_type === 'all'?'':params.training_type
      let res = await getTrainingcourses(params);
      that.course = res.data.items.map((item) => {
        let tmp = item;
        // tmp.techer = item.childVOs.length > 0 ? item.childVOs[0] : null;
        return tmp;
      });
      console.log("职业培训数据:",that.course)
      this.total = res.data.totalCount;
      // this.totalPage = res.data.totalPage
      this.totalPage = Math.ceil(res.data.totalCount / this.params.pageSize);
      that.fullscreenLoading = false;
    },
    setCurrentPage(val) {
      this.params.pageNo = val;
      this.getData();
    },
    toCourse(id) {
      this.$router.push({path:"/vocational/course",query:{id:id}});
    },
    choseNatureHandle(val) {
      this.params.course_type = val;
      this.getData();
    },
    choseTypeHandle(val) {
      this.params.training_type = val;
      this.getData();
    },
  },
  components: { Pagination },
};
</script>
<style scoped lang="scss">
.Vocational {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #eceef1;
}
.xs-empty-parent--relative{
  position: relative !important;
  height: 50px;
}
::v-deep .empty-mask{
  background-color:#eceef1;
  // background: blue;
  margin-top:100px;
  width: 179px;
  height: 50px;

  img{
    width: 150px;
    height: 150px;
  }
}

::v-deep .empty-content{
  margin: 0 auto;
  // background: red;
  margin-left: -30px;
  
}
::v-deep .empty-icon{
    margin: 0 auto 0 auto
}

.search {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  // height: 248px;
  // background-image: url("https://tse4-mm.cn.bing.net/th/id/OIP-C.-My6wAxkDPZKx3ocBlq5SwHaEK?w=315&h=180&c=7&r=0&o=5&dpr=1.25&pid=1.7");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  // margin-top: 24px;
  // margin-bottom: 20px;
  margin: 24px auto;
  background: #ffffff;
  .searchContainer {
    width: 696px;
    height: 38px;
    background: #ffffff;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    // padding-right: 36px;
    position: relative;
    ::v-deep .el-input__inner{
      border: 1px solid #2885FF;
      padding-left:32px;  
    }
    .input-button{
      width: 72px;
      height: 38px;
      cursor: pointer;
      background: #2885FF;
      border-radius: 0px 6px 6px 0px;
      position: absolute;
      padding: 1px 0;
      right: 0;
      line-height: 38px;
      text-align: center;
      font-size: 16px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #FFFFFF;
    }
    .searchIcon {
      position: absolute;
      left: 0;
      height: 12px;
      width: 12px;
      padding: 13px 13px 13px 12px;
      &:active {
        transform: scale(0.96);
      }
    }
    .input{
      border: none;
    }
    // ::v-deep .el-input__inner {
    //   border: none;
    // }
  }
}
.lineBox {
    width: 1200px;
    height: 1px;
    background: #F1F1F1;
    margin: 0 auto;
  }
.type-box{
  width: 1260px;
  margin: 0 auto;
  // background: #ffffff;
  // background: red;
  .typeCard {
    width: 100%;
    // height: 112px;
    // margin: 0 auto;
    // box-shadow: 0px 0px 9px 3px rgba(204, 221, 244, 0.5);
    // border-radius: 20px;
    filter: blur(0px);
    // padding: 20px 30px 20px 30px;
    padding-left: 30px;
    .type{
        color: #999999;
        font-size: 14px;
        // border-right: 1px solid #E5E5E5;
        margin-top: 24px;
        // overflow: hidden;
        .blockBox{
          display: inline-block;
          width: 1px;
          height: 20px;
          background: #E5E5E5;
          margin-right: 16px;
          margin-left: 14px;
          margin-bottom: -5px;
        }
    }
    .nature{
    // margin-top: 16px;
    color: #999999;
    font-size: 14px;
    // margin-bottom: 24px;
    padding-bottom: 7px;
    .block-Box{
    width:1px;
    height:20px;
    background:#E5E5E5;
    display: inline-block;
    margin-right: 16px;
    margin-left: 14px;
    margin-bottom: -5px;
  }
    }
    .el-btn {
      display: inline-block;
      cursor: pointer;
      margin: 0 16px 16px 0px;
      padding: 1px 8px;
      line-height: 21px;
      height: 22px;
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
          }
      .active {
        
        background: #F5F9FF;
        border-radius: 6px;
        color: #2885FF;
        border: 1px solid #2885FF;
      }
      .no-active {
        border: none;
        color: #404040;
        background-color: #FFFFFF;
      }
      .no-active:hover {
        color: #2885FF;
        border: none;
      }
  }
}

.classify {
  width: 1260px;
  height: 460px;
  margin-top: 24px;
}
.courses {
  // display: grid;
  // grid-template-columns: 1fr 1fr 1fr;
  /* padding: 20px; */
  // grid-gap: 24px 28px;
  // padding: 20px;
  // // display: flex;
  // justify-content: row;  
  // flex-wrap: wrap;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  padding: 0 28px;
  .course {
    width: 224px;
    height: 210px;
    // margin: 0 20px 0 0;
    background: #ffffff;
    // box-shadow: 0px 0px 3px 2px rgba(228, 228, 228, 0.5);
    // border: 1px solid #ddd;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    margin-bottom:20px;
    &:hover{
      // 动态放大
      cursor: pointer;
      z-index: 999;
      transition: linear 0.3s;
      // box-shadow: 0 12px 40px rgb(#000000, 15%);
      box-shadow: 0px 19px 40px rgba($color: #000000, $alpha: 0.12);
    }
    .courseCover {
      // position: relative;
      // background: black;
      .courseInfo {
        position: absolute;
        font-size: 12px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #999999;
        text-align: center;
        left: 20px;
        bottom: 20px;
      }
    }
    .courseCover .themImg {
      width: 100%;
      height: 220px;
      background: #d8d8d8;
    }
    .teacher {
      height: 68px;
      font-size: 28px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #101010;
      line-height: 68px;
    }
    .teacher > p {
      // margin-top: 22px;
      margin-top: 16px;
      padding-left: 20px;
      font-size: 16px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 600;
      color: #101010;
      line-height: 24px;
    }

    // margin: 0 10px 40px;
  }
}
::v-deep .el-image__inner{
  width: 184px;
  height: 110px;
  // padding: 20px 20px 20px 80px;
  margin-top: 20px;
  margin-left: 20px;
  border-radius: 3px;
}
.Pagination {
  width: 100%;
  height: 78px;
  display: flex;
  justify-content: center;
  // padding: 60px 0 80px 0;
  margin-top: -20px;
}

@media screen and (max-width: 768px) {
}
</style>

